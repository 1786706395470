<template>
    <div class="row dashboard-card">
        <div class="col-4 p-4" v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('drive') !== -1">
            <router-link to="/drive/">
                <el-card class="bg-light-primary p-5 rounded-2 text-center dashboard-card">
                    <div>
                        <img src="/media/menu/menu-drive.svg" />
                    </div>
                    <div class="mt-5 mb-5">
                        <h2>
                            <a href="" class="text-primary fw-bold"> {{ $t("pages.dashboard.drive") }}</a>
                        </h2>
                    </div>
                </el-card>
            </router-link>
        </div>
        <div
            class="col-4 p-4"
            v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('attendance') !== -1"
        >
            <router-link to="/attendance-monitoring/">
                <el-card class="bg-light-primary p-5 rounded-2 text-center dashboard-card">
                    <div>
                        <img src="/media/menu/menu-attendance.svg" />
                    </div>
                    <div class="mt-5 mb-5">
                        <h2>
                            <a href="#" class="text-primary fw-bold"> {{ $t("pages.dashboard.attendanceMonitoring") }}</a>
                        </h2>
                    </div>
                </el-card>
            </router-link>
        </div>
        <div
            class="col-4 p-4"
            v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('results') !== -1"
        >
            <router-link to="/results/">
                <el-card class="bg-light-primary p-5 rounded-2 text-center h-100">
                    <div>
                        <img src="/media/menu/menu-results.svg" />
                    </div>
                    <div class="mt-5 mb-5">
                        <h2>
                            <a href="#" class="text-primary fw-bold"> {{ $t("pages.dashboard.results") }}</a>
                        </h2>
                    </div>
                </el-card>
            </router-link>
        </div>
        <div
            class="col-4 p-4"
            v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('roadmap') !== -1"
        >
            <router-link to="/roadmap/">
                <el-card class="bg-light-primary p-5 rounded-2 text-center h-100">
                    <div>
                        <img class="w-100" src="/media/menu/roadmap.svg" />
                    </div>
                    <div class="mt-5 mb-5">
                        <h2>
                            <a href="#" class="text-primary fw-bold"> {{ $t("pages.roadmap.title") }}</a>
                        </h2>
                    </div>
                </el-card>
            </router-link>
        </div>
        <div
            class="col-4 p-4"
            v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('ideas_box') !== -1"
        >
            <router-link to="/ideas/">
                <el-card class="bg-light-primary p-5 rounded-2 text-center h-100">
                    <div>
                        <img src="/media/menu/ideabox.svg" />
                    </div>
                    <div class="mt-5 mb-5">
                        <h2>
                            <a href="#" class="text-primary fw-bold"> {{ $t("pages.ideas.title") }}</a>
                        </h2>
                    </div>
                </el-card>
            </router-link>
        </div>
        <div
            class="col-4 p-4"
            v-if="this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('useful-training') !== -1"
        >
            <router-link to="/useful-training/">
                <el-card class="bg-light-primary p-5 rounded-2 text-center h-100">
                    <div>
                        <img src="/media/menu/learning.svg" />
                    </div>
                    <div class="mt-5 mb-5">
                        <h2>
                            <a href="#" class="text-primary fw-bold"> {{ $t("pages.usefulTraining.title") }}</a>
                        </h2>
                    </div>
                </el-card>
            </router-link>
        </div>
    </div>
</template>
<style>

.dashboard-card img{
  height:150px;
  width:auto;
  margin-bottom:20px;
}
</style>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
    name: "dashboard",
    components: {},
    setup() {
        onMounted(() => {
            setCurrentPageTitle("Dashboard");
        });
    },
});
</script>
