
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
    name: "dashboard",
    components: {},
    setup() {
        onMounted(() => {
            setCurrentPageTitle("Dashboard");
        });
    },
});
